import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import forEach from 'lodash/forEach'
import { reactive, ref } from '@vue/composition-api'

import { createFolder } from '@/helper/damUtils'
import { v4 as uuidv4 } from 'uuid'

export default (ctx) => {
  const {
    root: { $store },
  } = ctx

  const parseStringToJson = (str) => {
    try {
        return JSON.parse(str)
    } catch (e) {
        return null
    }
  }

  const submitLabel = ref("สร้าง")

  const initialData = reactive({
    id: null,
    brand_lang_id: null,
    lang: null,
    slug: null,
    created_by: null,
    updated_by: null,
    updated_at: null,
    priority: null,
    brand_name: null,
    brand_concept: null,
    brand_description: null,
    brand_folder_name: null,
    brand_talk_title: null,
    brand_story_url: null,
    brand_talk_theme_color: {},
    has_brand_talk: false,
    brand_logo: null,
    brand_talks: [
      {
        brand_talk_sub_title: '',
        brand_talk_sub_description: '',
        brand_talk_sub_detail: '',
        position: 0
      },
      {
        brand_talk_sub_title: '',
        brand_talk_sub_description: '',
        brand_talk_sub_detail: '',
        position: 0
      },
      {
        brand_talk_sub_title: '',
        brand_talk_sub_description: '',
        brand_talk_sub_detail: '',
        position: 0
      },
      {
        brand_talk_sub_title: '',
        brand_talk_sub_description: '',
        brand_talk_sub_detail: '',
        position: 0
      }
    ]
  })

  const formData = reactive({
    id: null,
    brand_lang_id: null,
    lang: null,
    slug: null,
    priority: null,
    created_by: null,
    updated_by: null,
    updated_at: null,
    brand_name: null,
    brand_concept: null,
    brand_description: null,
    brand_folder_name: null,
    brand_talk_title: null,
    brand_story_url: null,
    has_brand_talk: false,
    brand_logo: null,
    brand_talk_theme_color: {},
    brand_talks: [
      {
        brand_talk_sub_title: '',
        brand_talk_sub_description: '',
        brand_talk_sub_detail: '',
        position: 0
      },
      {
        brand_talk_sub_title: '',
        brand_talk_sub_description: '',
        brand_talk_sub_detail: '',
        position: 0
      },
      {
        brand_talk_sub_title: '',
        brand_talk_sub_description: '',
        brand_talk_sub_detail: '',
        position: 0
      },
      {
        brand_talk_sub_title: '',
        brand_talk_sub_description: '',
        brand_talk_sub_detail: '',
        position: 0
      }
    ]
  })

  const selectedImage = ref([])

  const prepareFormData = (result) => {
    const data = omit(result, ['__typename'])
    forEach(formData, (value, key) => {
      // parse to json
      if (key === 'brand_talk_theme_color') {
        formData[key] = data[key] ? parseStringToJson(data[key]) : {}
        initialData[key] = data[key] ? parseStringToJson(data[key]) : {}
      } else {
        formData[key] = data[key] ? data[key] : null
        initialData[key] = data[key] ? data[key] : null
      }

    })

    // initial brand talk
    if (isEmpty(data.brand_talks)) {
      initialData.brand_talks = [
        {
          brand_talk_sub_title: '',
          brand_talk_sub_description: '',
          brand_talk_sub_detail: '',
          position: 0
        },
        {
          brand_talk_sub_title: '',
          brand_talk_sub_description: '',
          brand_talk_sub_detail: '',
          position: 0
        },
        {
          brand_talk_sub_title: '',
          brand_talk_sub_description: '',
          brand_talk_sub_detail: '',
          position: 0
        },
        {
          brand_talk_sub_title: '',
          brand_talk_sub_description: '',
          brand_talk_sub_detail: '',
          position: 0
        }
      ]
      formData.brand_talks = initialData.brand_talks
    }

    initialData.has_brand_talk = data.has_brand_talk === 'true'
    formData.has_brand_talk = data.has_brand_talk === 'true'

    initialData.priority = data.priority
    formData.priority = data.priority

    selectedImage.value.push(formData.brand_logo)
  }

  const serializeData = (data) => {
    const dataSerialized = Object.assign({}, {
      ...omit(data, ['brand_logo', 'created_at', 'updated_at'])
    })
    
    const logo = get(data, 'brand_logo', null)
    const hasBrandTalk = get(data, 'has_brand_talk', false)

    if (logo) {
      dataSerialized.brand_files = [
        {
          attribute_code: 'brand_logo',
          ...logo
        }
      ]
    }
    if (!hasBrandTalk) {
      dataSerialized.brand_talks = []
      dataSerialized.brand_talk_title = null
      dataSerialized.brand_story_url = null
      dataSerialized.brand_talk_theme_color = null
    } else if (!isEmpty(data.brand_talk_theme_color) && hasBrandTalk) {
      dataSerialized.brand_talk_theme_color = JSON.stringify(data.brand_talk_theme_color)
    }

    const activeUser = ctx.root.$store.state.AppActiveUser
    const email = get(activeUser, 'email', '')
    // add user created by
    if (!data.created_by) {
      dataSerialized.created_by = email
    }
    dataSerialized.updated_by = email

    return dataSerialized
  }

  const fetchByRouteId = async () => {
    const id = get(ctx.root.$router.currentRoute.params, 'id', null)
    const folders = ['Logo']
    if (id) {
      submitLabel.value = "แก้ไข"
      formData.id = id
      $store
        .dispatch('brand/fetchOne', id)
        .then(async (result) => {
          prepareFormData(result)
          if (!get(formData, ['brand_folder_name'], null)) {
            const randomUuid = uuidv4()
            formData.brand_folder_name = randomUuid
            await $store.dispatch('brand/createFolderName', formData)
          }
          await createFolder(`brands/${formData.brand_folder_name}`, folders)
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      const randomUuid = uuidv4()
      ctx.root.$vs.loading()
      formData.brand_folder_name = randomUuid
      await createFolder(`brands/${randomUuid}`, folders)
      ctx.root.$vs.loading.close()
    }
  }

  return {
    initialData,
    formData,
    selectedImage,
    prepareFormData,
    fetchByRouteId,
    submitLabel,
    serializeData
  }
}
