<template>
  <div>
    <ValidationObserver v-slot="context">
      <form @submit.prevent="submit(context)">
        <vx-card class="mb-base" title="Infomation">
          <FormLayout>
            <FormLabel>
              <span>ชื่อแบรนด์</span>
              <span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="ชื่อแบรนด์" 
                rules="required">
                <vs-input
                  v-model="formData.brand_name"
                  class="w-full"
                  name="title"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span class="required">ระบุ URL พิเศษ (Slug)</span><br>
              <span class="text-warning text-sm">URL พิเศษนี้ใช้รวมกันทั้ง 3 ภาษา</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Slug" 
                rules="required|special_charecter">
                <vs-input
                  v-model="formData.slug"
                  class="w-full"
                  name="title"
                  :disabled="currentLang != 'th'"
                  @focus="() => isSlugExist = false"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
                <br v-show="isSlugExist && errors.length > 0" />
                <span 
                  v-show="isSlugExist" 
                  class="text-danger text-sm"
                >slug นี้มีในระบบแล้ว กรุณากรอกข้อมูลใหม่</span
                >
              </ValidationProvider>
              <span class="text-gray flex flex-col">ตัวอย่าง slug: ladawan, vive</span>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>Logo</span>
              <span class="text-danger"> *</span><br >
              <span class="text-warning text-sm">รองรับ .jpg / .png / .gif</span><br >
              <span class="text-warning text-sm">ขนาดไม่เกิน 150 x 150 px</span><br >
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="Logo"
                rules="required|lh_mimes:jpg,png,gif|lh_image_max_resolution:150,150"
              >
                <ImageUpload
                  v-model="selectedImage"
                  :folder="`${baseFolder}/brands/${formData.brand_folder_name}/Logo`"
                  selection-name="thumbnail"
                  crop="scale"
                  has-delete-button
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>Concept</span>
            </FormLabel>
            <FormContent>
              <vs-textarea
                v-model="formData.brand_concept"
                class="w-full"
                name="conecpt"
                rows="8"
              />
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รายละเอียด Brand</span>
            </FormLabel>
            <FormContent>
              <vs-textarea
                v-model="formData.brand_description"
                class="w-full"
                name="description"
                rows="8"
              />
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>เพิ่มข้อมูล Brand Talk</span>
            </FormLabel>
            <FormContent>
              <vs-checkbox 
                v-model="formData.has_brand_talk"
                class="w-8/12 pt-2" 
                name="has_brand_talk" />
            </FormContent>
          </FormLayout>
        </vx-card>
        <BrandTalk
          v-if="formData.has_brand_talk"
          v-model="formData"
          :folder="`${baseFolder}/brands/${formData.brand_folder_name}`"
        />
        <vx-card>
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดโดย</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formData.updated_by}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดวันที่</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel/>
            <FormContent>
              <vs-button 
                class="mt-8 w-1/3" 
                @click="submit(context)">{{submitLabel}}</vs-button>
            </FormContent>
          </FormLayout>
        </vx-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import get from 'lodash/get'
import forEach from 'lodash/forEach'
import { onMounted, computed, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import ImageUpload from '@/components/upload/ImageUpload'
import useCrud from '@/use/useCrud'
import useNotify from '@/use/useNotify'
import useBrandUtil from '@/use/useBrandUtil'
import env from '@/env'

import BrandTalk from './BrandTalk'

export default {
  name: 'BrandForm',
  components: {
    ImageUpload,
    ValidationObserver,
    BrandTalk
  },
  setup(props, ctx) {
    const {
      initialData,
      formData,
      selectedImage,
      fetchByRouteId,
      submitLabel,
      serializeData
    } = useBrandUtil(ctx)
    const { success: notifySuccess, error: notifyError } = useNotify(ctx)
    const {
      root: { $store, $router, $vs },
    } = ctx
    const crudFunction = useCrud(ctx, 'brand')
    crudFunction.routePrefix.value = 'property-brand'
    const isSlugExist = ref(false)

    onMounted(() => {
      fetchByRouteId()
    })

    const updateItem = ({ validate }) => {
      const body = serializeData(formData)
      validate().then((result) => {
        if (!result) {
          // scroll to top when not input slug
          if (!formData.slug) {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
            return notifyError({
              text: "กรุณาระบุ Slug"
            })
          }

          formData.slug =  formData.slug.replace(/\s+/g, '-')

          return notifyError({ text: "กรุณาตรวจสอบข้อมูลที่กรอก" })
        }

        $vs.loading()
        $store
          .dispatch('brand/updateItem', body)
          .then(() => {
            $router.push({ name: 'property-brand' })
            $vs.loading.close()
            notifySuccess({ text: `${formData.id ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย` })
          })
          .catch(() => {
            $vs.loading.close()
            // notifyError({ text: JSON.stringify(error) })
            notifyError({ text: "เกิดข้อผิดพลาด กรุณารีเฟรชแล้วลองใหม่อีกครั้ง" })
          })
      })
    }

    const reset = () => {
      forEach(formData, (value, key) => {
        formData[key] = initialData[key]
      })
    }

    const submit = async (ctx) => {
      const image = get(selectedImage, 'value.0', '')
      formData.brand_logo = image
      if (formData.slug) {
        formData.slug =  formData.slug.replace(/\s+/g, '-')
        const isExist = await $store.dispatch('brand/checkSlugExist', formData)
        isSlugExist.value = isExist

        if (isExist) {
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0

          return notifyError({
            text: "มี Slug นี้ในระบบแล้ว"
          })
        }
      }

      const body = serializeData(formData)
      if (formData.id) {
        updateItem(ctx)
      } else {
        crudFunction.addItem(ctx, body)
      }
    }

    const baseFolder = computed(() => env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)

    const currentLang = computed(() => $store.state.locale.currentLang)

    return {
      ...crudFunction,
      initialData,
      formData,
      selectedImage,
      updateItem,
      submit,
      reset,
      baseFolder,
      submitLabel,
      isSlugExist,
      currentLang
    }
  },
}
</script>
