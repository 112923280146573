<template>
  <div>
    <vx-card class="mb-base" title="BrandTalk">
      <FormLayout class="flex flex-col text-left">
        <FormLabel custom-class="vx-col w-full md:w-6/12 py-2">
          <span>Title Brand Talk</span>
          <span class="text-danger"> *</span>
          <p class="text-warning">**Support การ Upload Video ขนาดไม่เกิน 98MB</p>
        </FormLabel>
        <FormContent custom-class="vx-col w-full">
          <ValidationProvider
            v-slot="{ errors }" 
            name="Title brand talk" 
            rules="required">
            <Wyswyg                     
              :folder="`${folder}/wyswyg`"
              v-model="$attrs.value.brand_talk_title" />
            <span
              v-if="errors.length > 0" 
              class="text-danger text-sm">{{
                errors[0]
              }}
            </span>
          </ValidationProvider>
        </FormContent>
      </FormLayout>
      <FormLayout>
        <FormLabel>
          <span>Url Brand Story</span>
          <span class="text-danger"> *</span>
        </FormLabel>
        <FormContent>
          <ValidationProvider
            v-slot="{ errors }"
            name="Url Brand Story"
            rules="required"
          >
            <vs-input
              v-model="$attrs.value.brand_story_url"
              class="w-full"
              name="brand_story_url"
            />
            <span 
              v-if="errors.length > 0" 
              class="text-danger text-sm">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </FormContent>
      </FormLayout>
      <FormLayout>
      <FormLabel>
        กำหนด theme สีของ brand talk <span class="text-danger">*</span>
        <br> <span class="text-warning">*theme สีจะถูกใช้กับ Title หมวดหมู่และปุ่มลูกศร</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          rules="required"
          name="สี theme"
        >
          <input
            ref="colorPicker"
            v-model="state.color"
            :style="{
              backgroundColor,
              color: textColor
            }"
            class="color-input vs-inputx vs-input--input normal w-3/12"
            autocomplete="off"
            name="brand_talk_theme_color"
          >
          <span 
            v-if="errors.length > 0" 
            class="text-danger text-sm">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
      <vs-divider />
      <div
        v-for="(index) in 4"
        :key="index"
      >
        <BrandTalkSubSection
          :id="index"
          :folder="folder"
          :length="4"
          v-model="$attrs.value.brand_talks[parseInt(index-1)]"
        />
      </div>
    </vx-card>
  </div>
</template>

<script>
import get from "lodash/get"
import Wyswyg from '@/components/Wyswyg'
import Huebee from 'huebee'
import 'huebee/dist/huebee.min.css'
import BrandTalkSubSection from './BrandTalkSubSection'

import { ref, onMounted, watch, reactive, computed } from '@vue/composition-api'
export default {
  name: 'BrandTalkForm',
  components: {
    BrandTalkSubSection,
    Wyswyg
  },
  props: {
    folder: {
      type: String,
      default: ''
    }
  },
  setup(_, ctx) {
    const huebee = ref(null)
    const state = reactive({
      color: '',
    })

    const backgroundColor = computed(() => {
      return get(ctx, ['attrs', 'value', 'brand_talk_theme_color', 'hex'], null)
    })

    const textColor = computed(() => {
      return get(ctx, ['attrs', 'value', 'brand_talk_theme_color', 'text_color'], null)
    })

    watch(
      () => ctx.attrs.value.brand_talk_theme_color.hex,
      (newValue) => {
        state.color = newValue
      },
    )

    onMounted(() => {
      huebee.value = new Huebee('.color-input', {
        // options
        notation: 'hex',
        saturations: 2,
        shades: 7,
        hue0: 210
      })

      huebee.value.on('change', function(color) {
        ctx.attrs.value.brand_talk_theme_color = {
          hex: color,
          text_color: ctx.refs.colorPicker.style.color
        }
      })
    })

    return {
      backgroundColor,
      textColor,
      state
    }
  },
}
</script>
