var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vx-card',{staticClass:"mb-base",attrs:{"title":"BrandTalk"}},[_c('FormLayout',{staticClass:"flex flex-col text-left"},[_c('FormLabel',{attrs:{"custom-class":"vx-col w-full md:w-6/12 py-2"}},[_c('span',[_vm._v("Title Brand Talk")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")]),_c('p',{staticClass:"text-warning"},[_vm._v("**Support การ Upload Video ขนาดไม่เกิน 98MB")])]),_c('FormContent',{attrs:{"custom-class":"vx-col w-full"}},[_c('ValidationProvider',{attrs:{"name":"Title brand talk","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Wyswyg',{attrs:{"folder":(_vm.folder + "/wyswyg")},model:{value:(_vm.$attrs.value.brand_talk_title),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "brand_talk_title", $$v)},expression:"$attrs.value.brand_talk_title"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0])+"\n          ")]):_vm._e()]}}])})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("Url Brand Story")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Url Brand Story","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"brand_story_url"},model:{value:(_vm.$attrs.value.brand_story_url),callback:function ($$v) {_vm.$set(_vm.$attrs.value, "brand_story_url", $$v)},expression:"$attrs.value.brand_story_url"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n            "+_vm._s(errors[0])+"\n          ")]):_vm._e()]}}])})],1)],1),_c('FormLayout',[_c('FormLabel',[_vm._v("\n      กำหนด theme สีของ brand talk "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('br'),_c('span',{staticClass:"text-warning"},[_vm._v("*theme สีจะถูกใช้กับ Title หมวดหมู่และปุ่มลูกศร")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"rules":"required","name":"สี theme"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.state.color),expression:"state.color"}],ref:"colorPicker",staticClass:"color-input vs-inputx vs-input--input normal w-3/12",style:({
            backgroundColor: _vm.backgroundColor,
            color: _vm.textColor
          }),attrs:{"autocomplete":"off","name":"brand_talk_theme_color"},domProps:{"value":(_vm.state.color)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.state, "color", $event.target.value)}}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n          "+_vm._s(errors[0])+"\n        ")]):_vm._e()]}}])})],1)],1),_c('vs-divider'),_vm._l((4),function(index){return _c('div',{key:index},[_c('BrandTalkSubSection',{attrs:{"id":index,"folder":_vm.folder,"length":4},model:{value:(_vm.$attrs.value.brand_talks[parseInt(index-1)]),callback:function ($$v) {_vm.$set(_vm.$attrs.value.brand_talks, parseInt(index-1), $$v)},expression:"$attrs.value.brand_talks[parseInt(index-1)]"}})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }