<template>
  <div>
    <h5>Brand Talk หมวด {{id}}</h5>
    <FormLayout>
      <FormLabel>
        <span>Title</span>
        <span class="text-danger"> *</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider 
          v-slot="{ errors }" 
          :name="`Brand Talk หมวด ${id} Title`" 
          rules="required">
          <vs-input
            v-model="$attrs.value.brand_talk_sub_title"
            class="w-full"
            :name="`brand_talk_sub_title_${id}`"
          />
          <span 
            v-if="errors.length > 0" 
            class="text-danger text-sm">{{
              errors[0]
            }}</span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>Description</span>
        <span class="text-danger"> *</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider 
          v-slot="{ errors }" 
          :name="`Brand Talk หมวด ${id} Description`" 
          rules="required">
          <vs-input
            v-model="$attrs.value.brand_talk_sub_description"
            class="w-full"
            :name="`brand_talk_sub_description_${id}`"
          />
          <span 
            v-if="errors.length > 0" 
            class="text-danger text-sm">{{
              errors[0]
            }}</span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout class="flex flex-col text-left">
      <FormLabel custom-class="vx-col w-full md:w-6/12 py-2">
        <span>Detail</span>
        <span class="text-danger"> *</span>
        <p class="text-warning">**Support การ Upload Video ขนาดไม่เกิน 98MB</p>
      </FormLabel>
      <FormContent custom-class="vx-col w-full">
        <ValidationProvider
          v-slot="{ errors }" 
          :name="`Brand Talk หมวด ${id} Detail`" 
          rules="required">
          <Wyswyg                     
            :folder="`${folder}/wyswyg`"
            v-model="$attrs.value.brand_talk_sub_detail" />
          <span 
            v-if="errors.length > 0" 
            class="text-danger text-sm">{{
              errors[0]
            }}</span>
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <vs-divider v-if="id != length" />
  </div>
</template>

<script>
import Wyswyg from '@/components/Wyswyg'

export default {
  name: 'BrandTalkSubForm',
  components: {
    Wyswyg
  },
  props: {
    id: {
      type: Number,
      default: 1,
    },
    length: {
      type: Number,
      default: 4,
    },
    folder: {
      type: String,
      default: ''
    }
  },
  setup(props, ctx) {
    ctx.attrs.value.position = props.id
  },
}
</script>
